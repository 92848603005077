window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.content_item_editor = window.locales.am.content_item_editor || {};window.locales.am.content_item_editor.content_item_edit_locale = window.locales.am.content_item_editor.content_item_edit_locale || {};window.locales.am.content_item_editor.content_item_edit_locale = {...window.locales.am.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "ይህ የሚከተለው ትርጉም ነው፡-",           
    "unlink": "ከትርጉሞች ጋር ያለውን ግንኙነት አቋርጥ",           
    "groups_are_shared": "ቡድኖች ከሁሉም ከተገናኙ ትርጉሞች መካከል ይጋራሉ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.content_item_editor = window.locales.ar.content_item_editor || {};window.locales.ar.content_item_editor.content_item_edit_locale = window.locales.ar.content_item_editor.content_item_edit_locale || {};window.locales.ar.content_item_editor.content_item_edit_locale = {...window.locales.ar.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "هذه هي ترجمة لـ:",           
    "unlink": "قطع الربط بين الترجمات",           
    "groups_are_shared": "تمت مشاركة المجموعات بين جميع الترجمات المرتبطة."           
}
};window.locales.en = window.locales.en || {};window.locales.en.content_item_editor = window.locales.en.content_item_editor || {};window.locales.en.content_item_editor.content_item_edit_locale = window.locales.en.content_item_editor.content_item_edit_locale || {};window.locales.en.content_item_editor.content_item_edit_locale = {...window.locales.en.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "This is a translation of:",
    "unlink": "Unlink from translations",
    "groups_are_shared": "Groups are shared amongst all linked translations."
}
};window.locales.es = window.locales.es || {};window.locales.es.content_item_editor = window.locales.es.content_item_editor || {};window.locales.es.content_item_editor.content_item_edit_locale = window.locales.es.content_item_editor.content_item_edit_locale || {};window.locales.es.content_item_editor.content_item_edit_locale = {...window.locales.es.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "Esta es una traducción de:",           
    "unlink": "Desvincular de las traducciones",           
    "groups_are_shared": "Los grupos son compartidos entre todas las traducciones vinculadas."           
}
};window.locales.it = window.locales.it || {};window.locales.it.content_item_editor = window.locales.it.content_item_editor || {};window.locales.it.content_item_editor.content_item_edit_locale = window.locales.it.content_item_editor.content_item_edit_locale || {};window.locales.it.content_item_editor.content_item_edit_locale = {...window.locales.it.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "Questa è una traduzione di:",           
    "unlink": "Rimuovi il link dalle traduzioni",           
    "groups_are_shared": "I gruppi sono condivisi fra tutte le traduzioni collegate con link."           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.content_item_editor = window.locales.zh.content_item_editor || {};window.locales.zh.content_item_editor.content_item_edit_locale = window.locales.zh.content_item_editor.content_item_edit_locale || {};window.locales.zh.content_item_editor.content_item_edit_locale = {...window.locales.zh.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "这是翻译：",           
    "unlink": "取消翻译链接",           
    "groups_are_shared": "在所有链接的翻译间共享组。"           
}
};